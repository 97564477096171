import { useRef } from "react";

interface StackItemProps {
 url: string,
 OnClick?: () => void;
}

function GetRandomRange(min:number, max:number):number {
 return Math.random() * (max - min) + min;
}

/**Элемент галереи */
const StackItem = ({ url, OnClick }: StackItemProps) => {
 const sizeRef = useRef<HTMLDivElement>(null);

 //Уменьшение высоты на небольшую дельту
 const OnImageLoadHandle = () => {
  if (sizeRef) {
   if (sizeRef.current) {
    const heigth = sizeRef.current.offsetHeight;
    const delta = heigth * 0.1;
    sizeRef.current.style.height = `${heigth - (GetRandomRange(0, delta))}px`;
   }
  }
 }

 return (
  <div ref={sizeRef} className="stack-item" onClick={OnClick}>
   <img src={url} alt="" onLoad={OnImageLoadHandle} />
  </div>
 )
}

export default StackItem;