import './Accordition.css';

interface AccorditionProps {
 /**Заголовок */
 title: string,
 /**Раскрыт или нет */
 isOpen?: boolean,
 /**Элемент раскрытия */
 children?: string | JSX.Element | JSX.Element[],
 /**Событие изменения */
 OnChanged?: (flag: boolean) => void,
 index:number
}

/**Форма для раскрывающегося элемента */
const Accordition = ({ title, isOpen, children, OnChanged, index }: AccorditionProps) => {
 return (
  <div className='accordition'>
   <input type="checkbox" id={`accordition-check ${index}`} className='accordition-check'
    checked={isOpen}
    onChange={() => { if (OnChanged) OnChanged(!isOpen) }} />
   <label htmlFor={`accordition-check ${index}`} className='accordition-label'>
    <p className='accordition-title'>{title}</p>
    <div className='accordition-container'>
     {children}
    </div>
   </label>
  </div>
 )
}

export default Accordition;