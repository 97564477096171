import First from './First';
import './Main.css';
import About from './sections/About';
import Contacts from './sections/Contacts';
import Faq from './sections/Faq';
import Photos from './sections/Photos';
import Team from './sections/Team';
import Videos from './sections/Videos';

/**Главная страница */
const Main = () => {
 return (
  <div>
   <header className="header">
    <div className="container">
     <div className="header-row">

      <a className='header-icon' href='#' />

      <nav className="header-nav">
       <ul className="header-list">
        <li><a className="header-element" href='#about'>О нас</a></li>
        <li><a className="header-element" href='#team'>Составы</a></li>
        <li><a className="header-element" href='#videos'>Видео</a></li>
        <li><a className="header-element" href='#photos'>Фото</a></li>
        <li><a className="header-element" href='#contacts'>Контакты</a></li>
       </ul>
      </nav>

     </div>
    </div>
   </header>

   <First />
   <About />
   <Team />
   <Videos />
   <Photos/>
   <Faq/>
   <Contacts/>

   <footer className='footer'>
    <div className='footer-container'>
     <p>Revolution 2024</p>
    </div>
   </footer>
  </div>
 )
}

export default Main;