import './sections.css';
import './Photos.css';
import StackItem from './forms/StackItem';
import { useEffect, useRef, useState } from 'react';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { RemoveScroll } from 'react-remove-scroll';
import file from '../images/gallery/imagelist.txt';

const MainGalleryImages: string[] = [
 '/gallery/photo_2024-06-13_03-25-40 (2).jpg',
 '/gallery/photo_2024-06-13_03-16-57 (2).jpg',
 '/gallery/photo_2024-06-13_03-16-57.jpg',
 '/gallery/photo_2024-06-13_03-23-21 (2).jpg',
 '/gallery/photo_2024-06-13_03-23-24.jpg',
 '/gallery/photo_2024-06-13_03-23-27.jpg',
 '/gallery/horizontal02.jpg',
 '/gallery/photo_2024-06-06_00-43-54.jpg',
];

/**Секция фото */
const Photos = () => {
 const [images, _] = useState<string[]>([]);

 /**Открытие галереи */
 const [isOpen, setOpen] = useState(false);
 const [openIndex, setIndex] = useState(-1);

 const [leftImages, _1] = useState<string[]>(MainGalleryImages.slice(0, MainGalleryImages.length / 2));
 const [rigthImages, _2] = useState<string[]>(MainGalleryImages.slice(MainGalleryImages.length / 2, MainGalleryImages.length));

 /**Объект галереи */
 const gallery = useRef<ReactImageGallery>(null);

 useEffect(() => {
  if (images.length <= 0) {
   fetch(file).then(r => r.text()).then(text => {
    const lines = text.split('\n');
    for (const line of lines) {
     const path = `/gallery/${line}`.trim();
     images.push(path);
     if (images.length >= lines.length) break;
    }
   });
  }
 }, [])

 /**Выделение изображения */
 const OnImageSelectHandle = (url: string) => {
  let index = -1;

  for (let i = 0; i < images.length; i++) {
   const path = images[i];
   if (path === url) {
    index = i;
    break;
   }
  }

  setOpen(index >= 0);
  setIndex(index);
 }

 return (
  <section className='section-default' id='photos'>
   <div className="section-content" style={{ paddingBottom: '0' }}>
    <div className="container">
     <div className="section-row">

      <div className="section-title">
       <p className="title-info">
        ФОТО
       </p>
      </div>

      <div className="section-col">
       <div className="section-block">
        <div className="stack-panel">
         <div className="stack-column">
          {leftImages.map((e, index) => (<StackItem key={index} url={process.env.PUBLIC_URL + e} OnClick={() => OnImageSelectHandle(e)} />))}
         </div>

         <div className="stack-column">
          {rigthImages.map((e, index) => (<StackItem key={index} url={process.env.PUBLIC_URL + e} OnClick={() => OnImageSelectHandle(e)} />))}
         </div>
        </div>

        <div className="stack-actions">
         <span className="gallery-button" style={{margin: '0 auto 0'}} onClick={() => {setOpen(true); setIndex(0);}}>
          Еще фото
         </span>
        </div>

       </div>
      </div>

     </div>

     {images.length > 0 && isOpen && (
      <RemoveScroll enabled={isOpen}>
       <div className="gallery">
        <div className="gallery-container">
         <div className="gallery-block" onClick={() => setOpen(false)} />
         <div className="gallery-content">
          <ReactImageGallery ref={gallery} lazyLoad autoPlay={false} stopPropagation startIndex={openIndex}
           showPlayButton={false} showFullscreenButton={false} showIndex
           items={images.map<ReactImageGalleryItem>((e) => { return { original: e } })} />
         </div>

         <div className="gallery-actions">
          <p className="gallery-button" onClick={() => setOpen(false)}>
           Закрыть
          </p>
         </div>
        </div>
       </div>
      </RemoveScroll>
     )}

    </div>
   </div>
  </section>
 )
}

export default Photos;