import './sections.css';
import './Videos.css';
import YouTube from 'react-youtube';

/**Секция видео */
const Videos = () => {
 return (
  <section className='section-default' id='videos'>
   <div className="section-content">
    <div className="container">
     <div className="section-row">

      <div className="section-title">
       <p className="title-info">
        ВИДЕО
       </p>
      </div>

      <div className="section-col">
       <div className="section-block">

        <div className="video-block">
         <YouTube videoId='e9dyf77w9JQ' className='youtube-content'/>
         <YouTube videoId='Ic5T-uLg55I' className='youtube-content'/>
         <YouTube videoId='3PbvIz9bzu8' className='youtube-content'/>
         <YouTube videoId='kbq3Wc1XRtA' className='youtube-content'/>
        </div>

       </div>
      </div>

     </div>
    </div>
   </div>
  </section>
 )
}

export default Videos;