import './First.css';
import HeaderImage from './images/back/full/header-main.jpg';
import MainLogo from './images/main-logo.png';

/**Заглавный блок */
const First = () => {
 return (
  <div className="first-screen" id="main">

   {/* Изображение фона */}
   <div className="first-screen__wrapper" style={{ backgroundImage: `url(${HeaderImage})` }} />

   {/* Контент заглавного блока */}
   <div className="first-screen__content">
    <div className="container first-screen-container">

     <div className="first-main-logo">
      <img className='responsive' src={MainLogo} alt=''/>
     </div>

     <div className='first-main-block'>
      <div className='first-main-button'>
       <a className='main-button' href='#contacts'>Заказать сейчас</a>
      </div>
     </div>

    </div>
   </div>
  </div>
 )
}

export default First;