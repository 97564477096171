import './Team.css';
import './sections.css';
import DuoVertical from '../images/duo-vertical.jpg';
import BandHorizontal from '../images/band-horizontal.jpg';

/**Секция составы */
const Team = () => {
 return (
  <section className="section-default" id='team'>
   <div className="section-content">
    <div className="container">
     <div className="section-row">

      <div className="section-title">
       <p className="title-info">
        СОСТАВ
       </p>
      </div>

      <div className="section-col">
       <div className="section-block">

        <div className="team-form" style={{paddingBottom: '80px'}}>
         <div className="team-photo-duo">
          <img src={DuoVertical} alt="" />
         </div>

         <div className="team-info-duo">
          <p>Коллаборация неповторимого стиля и утонченного вкуса. 
           Артисты не просто перепевают известные хиты, они выходят за рамки обыденности, 
           создавая новый формат звучания. 
           Двухголосное исполнение вносит свежую волну в уже привычные композиции.</p>
         </div>
        </div>

        <div className="team-form">
         <div className="team-photo-band">
          <img src={BandHorizontal} alt="" />
         </div>

         <div className="team-info-band">
          <p>Яркая, смелая и стильная команда. 
           Удивительное явление в мире музыкальной индустрии. 
           Здесь жанры переплетаются между собой, палитра музыки и 
           чувств создают особую атмосферу праздника. 
           Revolution - это отличное настроение с первой ноты до последнего аккорда.</p>
         </div>
        </div>

       </div>
      </div>
     </div>
    </div>
   </div>
  </section>
 )
}

export default Team;