import './sections.css';
import './Contacts.css';
import ContactsBack from '../images/back/full/contacts-back.jpg';
import WhatsappIcon from '../images/social/whatsapp.png';
import VKIcon from '../images/social/vk.png';
import InstagramIcon from '../images/social/instagram.png';
import TelegramIcon from '../images/social/telegram.png';
import YoutubeIcon from '../images/social/youtube.png';

/**Секция контактов */
const Contacts = () => {
 return (
  <section className='section-default' id='contacts'>
   <div className="section-content" style={{paddingBottom: '0'}}>
    <div className="container back-styled" style={{ backgroundImage: `url(${ContactsBack})` }}>
     <div className="section-row">

      <div className="section-title">
       <p className="title-info">
        КОНТАКТЫ 
       </p>
      </div>

      <div className="section-col">
       <div className="section-block">
        <div className="contacts-block">
         <p className='contact-name'>ЕЛЕНА КОРФ</p>
         <h1>Организатор выступления</h1>
         <p>
          <a className='contact-phone' href="tel:+79517640564">+7 951 764-05-64</a>
         </p>

         <p className="contact-mail">
          elenakorfsinger@gmail.com
         </p>

         <ul className="social-list">
          <li>
           <a href="http://wa.me/+79517640564" target='_blank'>
            <img src={WhatsappIcon} alt="" />
           </a>
          </li>
          <li>
           <a href="https://vk.com/lena_korf" target='_blank'>
            <img src={VKIcon} alt="" />
           </a>
          </li>
          <li>
           <a href="https://www.instagram.com/elenakorf" target='_blank'>
            <img src={InstagramIcon} alt="" />
           </a>
          </li>
          <li>
           <a href="https://t.me/elena_korf" target='_blank'>
            <img src={TelegramIcon} alt="" />
           </a>
          </li>
          <li>
           <a href="https://www.youtube.com/@Elena_Korf_1" target='_blank'>
            <img src={YoutubeIcon} alt="" />
           </a>
          </li>
         </ul>
        </div>
       </div>
      </div>

     </div>
    </div>
   </div>
  </section>
 )
}

export default Contacts;