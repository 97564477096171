import './sections.css';
import './Faq.css';
import FaqBack from '../images/back/full/faq-back.jpg';
import Accordition from './forms/Accordition';
import { useState } from 'react';

/**Тип данных для вопроса */
type QuestionValue = {
 /**Вопрос */
 question: string,
 /**Ответ */
 answer: string | JSX.Element
}

/**Данные с вопросами и ответами */
const Question: QuestionValue[] = [
 {
  question: 'Можно ли заказать каждого артиста отдельно?',
  answer: 'Да, конечно. Каждый участник проекта выступает как сольный артист.'
 },
 {
  question: 'Возможен ли выезд артистов в другие города?',
  answer: 'Возможен, обсуждается индивидуально.'
 },
 {
  question: 'Возможно ли составить репертуар по предпочтениям?',
  answer: 'Каждый сет-лист  обговаривается индивидуально с заказчиком'
 },
 {
  question: 'Какими составами выступаете?',
  answer: <p>Соло (вокальное/инструментальное){<br/>}
  Дуэт Revolution DUO<br/>
  Cover band Revolution 4+ (2 вокалиста, саксофон и гитара)<br/>
  Сover band Revolution полный состав</p>
 },
 {
  question: 'Отчего зависит стоимость выступления?',
  answer: 'Дата, место проведения мероприятия, время работы артистов, состав группы, наличие или отсутствие аппаратуры на площадке, а также срочность заказа.'
 },
 {
  question: 'Вы работаете с Юр. лицом?',
  answer: 'Да, работаем.'
 },
 {
  question: 'Возможно ли заключить договор?',
  answer: 'Да, конечно.'
 },
];

/**Секция вопросов */
const Faq = () => {
 //Выбранный элемент
 const [selected, setSelected] = useState(-1);

 //Выбор элемента
 const OnSelected = (flag: boolean, index: number) => {
  if (flag) setSelected(index);
  else setSelected(-1);
 }

 return (
  <section className='section-default' id='faq'>
   <div className="section-content" >
    <div className="container faq-styled" style={{ backgroundImage: `url(${FaqBack})` }}>
     <div className="section-row">

      <div className="section-title">
       <p className="title-info">
        ВОПРОСЫ
       </p>
      </div>

      <div className="section-col">
       <div className="section-block">
        <div className="faq-block">
         <div className="faq-container">
          {Question.map((value, index) => {
           return (
            <Accordition key={index} index={index} title={value.question} isOpen={selected == index} OnChanged={(flag: boolean) => OnSelected(flag, index)}>
             <span style={{ fontSize: '18px', color: 'rgb(200, 200, 200)'}}>{value.answer}</span>
            </Accordition>
           )
          })}
         </div>
        </div>
       </div>
      </div>

     </div>
    </div>
   </div>
  </section>
 )
}

export default Faq;