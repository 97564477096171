import './About.css';
import './sections.css';
import ElenaFace from '../images/persons/elena-face.jpg';
import DenisFace from '../images/persons/denis-face.jpg';

/**Секция О нас */
const About = () => {
 return (
  <section className="section-default" id='about'>
   <div className="section-content">
    <div className="container">
     <div className="section-row">

      <div className="section-title">
       <p className="title-info">
        О НАС
       </p>
      </div>

      <div className="section-col">

       <div className="section-block">

        <div className="about-personal">
         <div className="personal-photo">
          <img src={ElenaFace} alt='' style={{ width: '100%', maxWidth: '100%', display: 'block', borderStyle: 'none' }} />
         </div>

         <div className="personal-info">
          <h2 style={{ letterSpacing: '5px' }}>ЕЛЕНА<br />КОРФ</h2>
          <p style={{ fontSize: '18px' }}>
          Певица, show-woman, финалист вокального шоу ANNA ASTI "Voice of Dubai", 
          участница телепроекта "Х-фактор", автор и исполнитель песен. 
          Основатель крупного музыкального проекта REVOLUTION. 
          </p>
         </div>
        </div>

        <div className="about-personal">
         <div className="personal-photo">
          <img src={DenisFace} alt='' style={{ width: '100%', maxWidth: '100%', display: 'block', borderStyle: 'none' }} />
         </div>

         <div className="personal-info">
          <h2 style={{ letterSpacing: '5px' }}>ДЕНИС<br />КАТРИЧЕНКО</h2>
          <p style={{fontSize: '18px'}}>
          Настоящий универсал: 
          харизматичный вокалист, 
          ведущий крупных мероприятий, гитарист, танцор. 
          </p>
         </div>
        </div>

       </div>

      </div>
     </div>
    </div>
   </div>
  </section>
 )
}

export default About;